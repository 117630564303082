/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Image, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-1h1aom0 --style1 bg--center --full --parallax pb--30 pt--30" name={"einleitung"} parallax={true} fullscreen={true} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/i/template/60/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/60/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/60/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/60/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/60/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/60/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/60/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/60/fullscreen-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --full --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="mt--30">
              
              <Title className="title-box fs--128 w--500 swpf--uppercase ls--50 lh--12" style={{"marginTop":130}} content={"<span style='color: white'>Ivan Kräuslich</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--20 w--400 swpf--uppercase ls--50 lh--2 mt--20" content={"<span style='color: var(--color-dominant)'>Die Vereinigung junger Architekten</span>"}>
              </Subtitle>

              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/o/3480/8f9224d6dc3a4a26a1be880e8c5cd8d1.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":180}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--02" name={"teillinie"} style={{"backgroundColor":"var(--color-custom-1)"}} layout={"l1"}>
        </Column>


        <Column className="pb--40 pt--40" name={"uber-mich"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--left fs--48 w--500 ls--001 lh--1" content={"<span>Ivan Kräuslich</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left fs--13 w--500 lh--16" style={{"maxWidth":320}} content={"<span style=\"var(--color-blend--50)\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks ändern.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Subtitle className="subtitle-box fs--36 w--500 ls--001 lh--12" content={"<span>+49 797 811 2X05</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--36 w--500 ls--001 lh--12 mt--02" content={"<span>info@vase-stranky.com</span>"}>
              </Subtitle>

              <Text className="text-box fs--13 lh--16" style={{"maxWidth":680}} content={"<span style=\"var(--color-blend--50)\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--25" name={"partei"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--16" content={"Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

              <Text className="text-box fs--13" style={{"maxWidth":390}} content={"<span style=\"var(--color-blend--50)\">Erstellen Sie Ihre eigene Website 10 Minuten. Keine Programmierkenntnisse sind erforderlich. Billig und professionell.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Text className="text-box fs--16" content={"2019"}>
              </Text>

              <Text className="text-box fs--13" style={{"maxWidth":390}} content={"<span style=\"var(--color-blend--50)\">Ivan Kräuslich,<br>Die Vereinigung junger Architekten</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}